import logo from './logo.svg';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Paper from '@mui/material/Paper';
import InfiniteScrollStep from './components/InfiniteScrollStep.js';

import logo_livi from './logo_livi.png'
import images_livi from './images.png'

function App() {
  const [value, setValue] = React.useState(0);

  return (
    <div className="App">
      <Box sx={{ flexGrow: 1 }}>

        <AppBar position="fixed" sx={{ bgcolor: "white" }}>
          <Toolbar >

            <img
              style={{
                height: 60,
              }}
              alt="LOGO"
              src={logo_livi}
            />

          </Toolbar>
        </AppBar>
        <Toolbar />
      </Box>
      <header className="App-header">
        <InfiniteScrollStep />
      </header>
      <AppBar position="fixed" sx={{ bgcolor: "white" ,top: 'auto', bottom: 0 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
        <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
        <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
      </BottomNavigation></AppBar>  <BottomNavigation/>
    </div>
  );
}

export default App;
