import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import Grid from '@mui/material/Grid2';
import step_ico from "../data/step.json";

// import ProductCard from "./ProductCard";
import CircularProgress from '@mui/material/CircularProgress';

const InfiniteScrollStep = () => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(2);
  const loaderRef = useRef(null);
  // const url="https://api.escuelajs.co/api/v1/products"; 

  const fetchData = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);
    axios
      .get(`https://api.escuelajs.co/api/v1/products?offset=${index}0&limit=12`)

      .then((res) => {
        if (res.length) {
          setItems((prevItems) => [...prevItems, ...res.data]);
          setIndex((prevIndex) => prevIndex + 1);
        }
      })
      .catch((err) => console.log(err));



    setIsLoading(false);
  }, [index, isLoading]);

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     const target = entries[0];
  //     if (target.isIntersecting) {
  //       fetchData();
  //     }
  //   });

  //   if (loaderRef.current) {
  //     observer.observe(loaderRef.current);
  //   }

  //   return () => {
  //     if (loaderRef.current) {
  //       observer.unobserve(loaderRef.current);
  //     }
  //   };
  // }, [fetchData]);

  useEffect(() => {
    // const getData = async () => {
    //   setIsLoading(true);
    //   try {
    //     const response = await axios.get(
    //        "https://api.escuelajs.co/api/v1/products?offset=10&limit=10"

    //     );

    //     setItems(response.data);
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   setIsLoading(false);
    // };
    const getData = async () => {
      setItems(step_ico);
    }
    getData();
  }, []);

  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 3, sm: 2, md: 3 }}>

        {items.map((item, index) => (
          <div style={{width:100}}>
            <Grid className="livy_step" sx={{ bgcolor: item.bgcolor, padding: 1, borderRadius: 1 }} >
              <img key={item.id} src={`./img/${item.id}.png`} />
            </Grid>
            {item.text}
          </div>
        ))}


      </Grid>

      <div ref={loaderRef}>{isLoading && <CircularProgress />}</div>
    </div>
  );
};

export default InfiniteScrollStep;